import movies from "./movie";
import series from "./series";
import site from "./site";
import system from "./system";

const AllReducers = {
  movies,
  series,
  site,
  system,
};

export default AllReducers;
